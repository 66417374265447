<template>
  <div class="login-contain">
    <div class="scroll">
      <div class="userinfo-box" :class="{ 'black-bc': !$isLinkGame }" :style="{
        background: verConfig.mineBG ? `url(${verConfig.mineBG})` : '',
      }">
        <div class="person-box">
          <div class="editinfo">
            <div class="left">
              <van-image round class="avatar" fit="cover" :src="userList.userimg?`${verConfig.url}${userList.userimg}`  : userImg" />
              <div class="personal" v-if="userInfo">
                <div class="name" :class="{ 'yellow-color': !$isLinkGame }">
                  {{ userList.username }}
                </div>
                <div class="use">
                  <span class="ID" :class="{ 'yellow-color': !$isLinkGame }">ID：{{ userList.id }}</span>

                  <span class="ID" :class="{ 'yellow-color': !$isLinkGame }">
                    {{ $t('my.credit') }}
                    ：{{ userList.credit }}</span>
                </div>
                <div class="vip" :class="{ 'yellow-color': !$isLinkGame }">
                  {{ userList.rank_title }}
                </div>
              </div>
              <div v-else style="font-size: 18px" :class="{ 'yellow-color': !$isLinkGame }">
                {{ $t('login.pleaseLogin') }}
              </div>
            </div>
            <div class="logout" @click="handleLogout">
              <van-icon name="arrow" :color="!$isLinkGame ? '#ffd400' : '#fff'" />
            </div>
          </div>
          <div class="text" :class="{ 'yellow-color': !$isLinkGame }">
            {{ $t('login.customerService') }}
          </div>
          <div class="new-img-com" v-if="verConfig.mineLogo">
            <img :src="verConfig.mineLogo" />
          </div>
          <div class="right_bar">
            <template>
              <Badge @click.stop="$router.push('/Message')" class="right" :color="verConfig.unreadBg || '#00adff'"
                v-if="!!unreadNum">
                <div class="text" slot="content" :style="{ color: verConfig.unreadColor || '' }">
                  {{ unreadNum }}
                </div>
                <van-icon name="envelop-o" size="32" color="#fff" @click.stop="$router.push('/Message')" />
              </Badge>
              <div class="right" v-else>
                <van-icon name="envelop-o" size="32" color="#fff" @click.stop="$router.push('/Message')" />
              </div>
            </template>
            <div class="balance" v-if="verConfig.homeShowBalanceAtTop">
              {{ $money(userInfo.money || 0) }}
            </div>
          </div>
        </div>
        <div class="service" @click="handleLinkService">
          <i class="iconfont icon-kefu"></i>
          <span> {{ $t('login.customerServiceTow') }}</span>
        </div>
      </div>
      <div class="usernull-box"></div>

      <div class="scrollLit">
        <!-- 宫格区域 -->
        <van-grid :column-num="3" ref="vanGrid">
          <van-grid-item v-for="(item, index) in gridInfo" :key="index" @click="toPage(item.topath)">
            <div slot="icon">
              <van-image v-show="index < 3" class="cover-item" slot="default" :src="item.img" fit="cover" />
              <div class="cover-item moneyText" slot="default" v-show="index >= 3">
                {{ verConfig.moneyType }}
                {{ item.img }}
              </div>
            </div>
            <div slot="text" class="text">{{ $t(item.text) }}</div>
          </van-grid-item>
        </van-grid>
        <div class="cell-box">
          <van-cell v-for="(item, index) in newList" :key="index" :title="$t(item.label)"
            @click="goPage(item.path, item.key)">
            <img slot="icon" :src="item.icon" alt="" class="payimg" />
            <i slot="right-icon" class="iconfont icon-jiantou"></i>
          </van-cell>
        </div>
        <div class="logout_wrap" @click="logout" v-if="!verConfig.hasNotLoginOut">
          {{ NotLoginLinkPage() ? $t('common.logOut') : $t('common.logOut') }}
        </div>
      </div>
    </div>
    <van-action-sheet v-model="show" :actions="Lans" @close="onClose" @select="onSelect" />
  </div>
</template>

<script>
import request, { isLinkGame, isGameGoLink,$get } from '@/utils/request.js'
import UserRecord from '@/components/UserRecord'
import { userinfo, getExtractList } from '@/api/use.js'
import { isAppIos } from '@/utils/unique'
import { ActionSheet, Badge } from "vant"
import { Lans } from "@/lang"
import Cookies from 'js-cookie'


import rechargeNav from '@/assets/images/index_user/nav_recharge.png'
import accountIcon from '@/assets/images/index_user/item_account.png'
import widthdrawals from '@/assets/images/index_user/nav_withdrawals.png'
import message from '@/assets/images/index_user/nav_mss.png'
import lottery from '@/assets/images/index_user/nav_lottery.png'
import lock from '@/assets/images/index_user/item_lock.png'
import bank from '@/assets/images/index_user/item_bank.png'
import ranking from '@/assets/images/index_user/item_rankinglist.png'
import scrapcard from '@/assets/images/index_user/item_scrapcard.png'
import sevcive from '@/assets/images/index_user/item_service.png'
import psyimg from '@/assets/images/index_user/item_psy.png'
import download from '@/assets/images/index_user/item_download.png'
import verification from '@/assets/images/index_user/item_verification.png'
import Personal from '@/assets/images/index_user/item_Personal.png'
import userImg from '@/assets/images/index_user/user.png'
import language from '@/assets/images/index_user/lang.png'

import axios from 'axios'
import qs from 'qs'
import sha1 from 'js-sha1'
import { downloadApp } from '@/utils/downloadUrl'
import { getItem, NotLoginLinkPage } from '@/utils/storage'

// import { LogLevel } from '@rongcloud/imlib-v4'
export default {
  name: '',
  components: {
    UserRecord,
    [ActionSheet.name]: ActionSheet,
    Badge
  },
  data() {
    return {
      userImg: userImg,
      unreadNum: 0,
      gridInfo: isLinkGame
        ? [{
          img: rechargeNav,

          text: 'login.deposit',
          topath: '/recharge'
        }, {
          img: widthdrawals,
          text: 'login.withdrawMoney',
          topath: '/withdraw'
        }, {
          img: lottery,
          text: 'login.lotteryBettingHistory',
          topath: '/single'
        }, {
          img: '0.00',

          text: 'login.accountBalance',
          topath: '/account'
        }, {
          img: '0.00',
          text: 'login.gameBalance',
          topath: '/game-info'
        }]
        : [
          {
            img: rechargeNav,

            text: 'login.deposit',
            topath: '/recharge'
          },
          {
            img: widthdrawals,

            text: 'login.withdrawMoney',
            topath: '/withdraw'
          },
          // {
          //   img: message,
          //   text: 'Ghi chú',
          //   topath: 'message',
          // },
          {
            img: lottery,
            text: 'login.history',
            topath: '/single'
          },
          // {
          //   img: '0.00',
          //   text: 'Yu Bao',
          //   topath: 'yubao',
          // },
          {
            img: '0.00',
            text: 'login.accountBalance',
            topath: '/account'
          }
        ],
      // 图标
      icon: [lock, psyimg, bank, ranking, scrapcard, sevcive, download],
      toPathText: ['/editpasword', '/user/pay_password', '/bank'],
      newList: [{
        icon: accountIcon,
        key: '0',
        label: 'login.historyChange',
        path: '/account'
      }, {
        label: 'login.changeLoginPassword',
        key: '1',
        icon: lock,
        path: '/editpasword'
      }, {
        label: 'login.bankInformationLink',
        key: '3',
        icon: bank,
        path: '/bank'
      },
      {
        // this.$t('login.verification')
        label: 'login.verification',
        key: '5',
        icon: verification,
        path: '/certification'
      },
      {
        // this.$t('login.verification')
        label: 'Personal.Personal',
        key: '8',
        icon: Personal,
        path: '/PersonalCenter'
      },
      {
        label: 'login.customerCare',
        // this.$t('login.downloadTheApp')
        key: '6',
        icon: sevcive,
        path: ''
      },
      // {
      //   label: 'login.downloadTheApp',
      //   key: '7',
      //   icon: download,
      //   path: ''
      // }, 
      {
        label: 'languageSett',
        key: '8',
        icon: language,
        path: 'language'
      }],
      isNavbar: null,
      userList: [],
      withdrawalList: [],
      Listloading: false,
      Listfinished: false,
      pages: 1,
      useinfo: 1,
      userid: '',
      username: '',
      userportraitUri: '',
      serviceURL: '',
      userInfo: null,
      bankInfo: {},
      show: false,
      Lans: Lans,
      language: Cookies.get("language")
    }
  },
  mounted() { },
  created() {
    this.userInfo = getItem('TOUTIAO_USER')
    this.getUserInfo()
    this.getUserBalance()
    this.getLotteryListHandler()
    this.getService()
    this.getBankInfo()
    this.getMessage();
  },
  methods: {
    async getMessage() {
      
      try {
        const res = await $get("/users/message", {
          perPage: 999,
          status: "unread",
        });
        const { ret, msg, data } = res.data;
        this.unreadNum = data.total || 0;
        if (!data.total) return;
        if (!!data.lists.length) {
          // this.welfareDialogShow = true;
          // this.welfareDialogContent = data.lists[0].content;
        }
      } catch (error) {
      }
    },
    onClose() {
      this.show = false;
    },
    onSelect(e) {
      this.$i18n.Locale = e.value;
      Cookies.set("language", e.value);
      this.language = e.value;
      this.show = false;
      location.reload();
    },
    getRongyunToken() {
      const params = {
        userId: this.userid,
        name: this.username,
        portraitUri: this.userportraitUri
      }
      const Nonce = Math.floor(Math.random() * (10000 - 99999) + 99999)
      const Timestamp = Date.parse(new Date())
      const Signature = sha1(`bRZVyHtt7S${Nonce}${Timestamp}`)
      axios({
        url: '/user/getToken.json',
        method: 'post',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'App-Key': 'e5t4ouvpe6y7a',
          'Content-Type': 'application/x-www-form-urlencoded',
          Nonce,
          Timestamp,
          Signature
        },
        timeout: 60000,
        withCredentials: true,
        validateStatus: (status) => status >= 200 && status < 300,
        data: qs.stringify(params)
      })
        .then((r) => {

          if (r.data.code === 200) {
            window.localStorage.userToken = r.data.token
          }
        })
        .catch((e) => {
          console.error(e)
        })
    },
    async getUserInfo() {
      const {
        data: { ret, data }
      } = await userinfo()

      if (ret === 1) {
        this.userList = data
        window.localStorage.userID = data.id
        window.localStorage.USERPHONE = data.userphone
        // this.gridInfo[3].img = data.yuebao_balance ? data.yuebao_balance : 0.0
        this.gridInfo[3].img = data.money
        this.userid = data.id
        this.username = data.username
        this.userportraitUri = data.userimg
        window.localStorage.userInfo = JSON.stringify(data)
        // this.getRongyunToken()
      }
    },
    async getUserBalance() {
      const {
        data: { data, ret }
      } = await request({
        method: 'POST',
        url: 'obgame/qm/balance'
      })
      if (ret === 1) {
        this.gridInfo[4].img = data.bal ? (data.bal * 1).toFixed(2) : '0.00'
      }
    },
    ListonLoad() {
      this.getLotteryListHandler()
    },
    NotLoginLinkPage,
    logout() {
      if (!NotLoginLinkPage()) {
        this.$router.push('/login')
        return
      }
      this.$dialog
        .confirm({
          title: this.$t('login.LogOut'),
          message: this.$t('login.doYouWantToLogOut'),
          cancelButtonText: this.$t('common.cancel'),
          confirmButtonText: this.$t('common.confirm'),
          confirmButtonColor: '#00aa88'
        })
        .then(() => {
          this.$store.commit('delteUer')
          this.$router.push('/login')
          window.location.reload()
          window.localStorage.clear()
        })
        .catch(() => {
          // on cancel
        })
    },
    async getLotteryListHandler() {
      this.Listloading = true
      const {
        data: { data, ret }
      } = await getExtractList({
        data: { take: 10 }
      })
      this.Listloading = false
      if (ret === 1) {
        this.withdrawalList = data
        this.Listfinished = true
      }
    },
    async getBankInfo() {
      try {
        const {
          data: { data, ret }
        } = await request({
          method: 'get',
          url: 'pay/lists'
        })
        if (ret === 1) {
          this.bankInfo = data[0] ? data[0].lists[0] || {} : {}
        }
      } catch (error) { }
    },
    async paySubmit(item) {
      const {
        data: { data, ret }
      } = await request({
        method: 'get',
        url: 'pay/submit',
        params: {
          id: this.bankInfo.id || 1,
          money: 9999999
          // account: this.account,
          // isbefore: 1,
        }
      })
      if (ret === 2) {
        window.location.href = data.url
      } else if (ret === 3) {
        this.$router.push({
          path: '/user/pay_bank',
          name: 'payBank',
          query: {
            payInfo: JSON.stringify(data),
            step: item.step,
            id: item.id,
            account: this.account
          }
        })
      } else {
        this.$toast('System exception, please try again later')
      }
    },
    // 点击每一หนึ่งสถานที่宫格跳转
    toPage(path) {
      if (path === '/recharge' && this.verConfig.useNewToRecharge) {
        this.paySubmit(this.bankInfo)
        return
      }
      if (!NotLoginLinkPage()) {
        this.$router.push('/login')
        return false
      }
      if (path === 'yubao') {
        this.$toast(this.$t('login.underConstructionPleaseStayTuned'))
        // this.$router.push('/discover')
        return false
      }
      path === 'message'
        ? // ? this.$toast('Under construction, stay tuned')
        this.$toast(this.$t('login.underConstructionPleaseStayTuned'))
        : path !== '/balance'
          ? this.$router.push(path)
          : console.info(this.$t('login.lotteryError'))
    },

    goPage(path, value) {
      if (path === 'language') {
        this.show = true;
        return
      }
      if (path) {
        if (!NotLoginLinkPage()) {
          this.$router.push('/login')
          return false
        }
        this.$router.push(path)
      } else {
        if (value == 6) {
          if (!NotLoginLinkPage()) {
            this.$router.push('/login')
            return false
          }
          this.handleLinkService()
        } else if (value == 7) {
          if (isAppIos()) {
            this.$toast(this.$t('login.APPOnlySupportsAndroidUsersDownload'))
            return false
          }
          window.location.href =
            localStorage.getItem('appurl_android') || downloadApp
        } else {
          // this.$toast('Under construction, stay tuned')
          this.$toast(this.$t('login.underConstructionPleaseStayTuned'))
        }
      }
    },
    handleLinkService() {
      window.location.href = this.serviceURL
    },
    async getService() {
      const {
        data: { data, ret }
      } = await request({
        method: 'get',
        url: 'other/config',
        params: {
          act: 'system',
          refresh: this.isLoading === true ? 1 : 0
        }
      })
      if (ret === 1) {
        this.serviceURL = data.service_url
        localStorage.setItem('appurl_android', data.appurl_android)
      }
    },
    handleLogout() {
      if (!NotLoginLinkPage()) {
        this.$router.push('/login')
        return false
      }
      this.$router.push('/logout')
    }
  }
}
</script>

<style lang="less" scoped>
.login-contain {
  .login-nav {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 99;
  }

  .logout_wrap {
    margin: 70px 40px 0;
    font-size: 16px * 2;
    color: #fff;
    background: #daad00;
    border-radius: 8px;
    line-height: 40px * 2;
    text-align: center;
  }

  .userinfo-box {
    position: relative;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 290px;
    // height: 360px;
    background-image: url('../../../assets/images/index_user/background.png');
    //   no-repeat;
    // background: #0083e1;
    background-size: cover;
    display: flex;

    // align-items: center;
    .person-box {
      width: 100%;
      position: relative;

      .editinfo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #fff;
        margin-top: 80px;

        .left {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-left: 22px;

          .avatar {
            width: 118px;
            height: 118px;
            margin-right: 32px;
          }

          .personal {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            font-family: "PingFang-Medium";
            font-size: 24px;
            color: #fffefe;

            .gender,
            .start {
              margin-left: 5px;
            }

            .gender {
              display: inline-block;
            }

            .use {
              margin: 15px 0;
            }

            .name {
              font-size: 30px;
            }

            .vip {
              width: 200px;
              height: 45px;
              border-radius: 10px;
              line-height: 45px;
              text-align: center;
              font-size: 20px;
              background-color: rgba(255, 255, 255, 0.4);
            }
          }
        }

        .logout {
          margin-right: 34px;
        }
      }

      .text {
        margin-left: 22px;
        margin-top: 22px;
        font: 20px "PingFang-Medium";
        color: rgba(237, 245, 245, 0.5);
      }

      .new-img-com {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 30px;
        box-sizing: border-box;
        position: absolute;
        right: 30px;
        bottom: 6px;
        height: 1.33333rem;

        >img {
          // height: 100px;
          height: 80%;
        }
      }

      .right_bar {
        position: absolute;
        left: 22px;
        top: 10px;
        display: flex;
        align-items: center;

        .right {
          /deep/ .van-badge {
            background: transparent !important;
            border: none;
            .text{
              color: #fff;
              font: 28px "PingFang-Medium";
            }
          }
        }
        img {
          width: calc(26rem / 16);
          height: calc(26rem / 16);
          margin-left: calc(10rem / 16);
        }

        .balance {
          color: #fff;
          margin-top: calc(10rem / 16);
        }
      }
    }

    .service {
      position: absolute;
      top: 20px;
      right: 0;
      min-width: 150px;
      height: 50px;
      line-height: 50px;
      background-color: #4479eb;
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
      color: #fff;
      font-size: 24px;
      box-sizing: border-box;
      padding-left: 10px;
      display: flex;
      align-items: center;

      .iconfont {
        font-size: 40px;
        margin-right: 8px;
      }
    }
  }

  .usernull-box {
    // position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    // height: 290px;
    // height: 360px;
    // background-color: #fff;
    z-index: 20;
  }

  .scrollLit {
    height: (100% - 290px);
    padding: 0 0 100px 0;
    // padding-top: 360px;
    overflow: auto;
    box-sizing: border-box;

    .van-grid-item__content {
      padding: 0;
    }

    .van-grid-item {
      height: 130px;

      .cover-item {
        width: 66px;
        height: 66px;
      }

      .moneyText {
        line-height: 66px;
        width: 120%;
        font-size: 30px;
        font-weight: 700;
        color: #ff0000;
      }

      .text {
        font-size: 24px;
      }
    }

    .cell-box {
      margin-top: 14px;

      .van-cell {
        height: 88px;
        padding-left: 24px;
        padding-right: 0;
        font-size: 28px;
        color: #242424;

        .iconfont {
          margin-right: 20px;
          font-size: 45px;
          color: #1ea8e8;
        }

        .icon-jiantou {
          margin-right: 24px;
          color: #242424;
          transform: rotate(-180deg);
        }

        .payimg {
          width: 40px;
          height: 40px;
          margin-right: 10px;
        }
      }

      // .van-cell:nth-child(4){
      //   .payimg{
      //     width: 50px;
      //     height: 50px;
      //   }
      // }
    }

    .record {
      height: 66px;
      line-height: 66px;
      font-size: 30px;
      color: #676666;
      text-align: center;
    }

    .van-list {
      .img {
        width: 66px;
        height: 66px;
      }

      .van-cell {
        height: 88px;
      }
    }
  }
}
</style>
